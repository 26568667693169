<template>
  <v-container>
    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnPrint"
        @click.stop="btnPrint()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-printer
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >imprimir
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnNew"
        @click.stop="btnNew()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-file-plus
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >nuevo
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnEdit"
        @click.stop="btnEdit()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-pencil
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >editar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnCancel"
        @click.stop="btnCancel()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >Cancelar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnSave"
        @click.stop="btnSave()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-check
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >salvar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''">
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'">
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="btnClose()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-window-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
    </v-toolbar>
    <v-card max-width="95%" class="mx-auto" flat v-show="loggedIn === 1">
      <v-divider></v-divider>
      <v-list
        subheader
        dense
        flat
        >
        <v-list-item-group
          v-model="settings">
          <template v-for="(item, index) in filteredRecipes">
            <v-divider :key="index"></v-divider>
            <v-list-item
              :key="item.title"
              @click.stop="takeItem(item)">

              <v-list-item-content>
                <v-list-item-title v-html="item.description"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                
                <v-icon
                  v-if="item.active!=='1'"
                  color="grey lighten-1"
                  >
                  mdi-star-outline
                </v-icon>
                <v-icon
                  v-else
                  color="yellow darken-3"
                  >
                  mdi-star
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'ReasonForm',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
      searchValue: null,
      showFind: false,
      //
      showBtnSave: false,
      showBtnCancel: false,
      showBtnEdit: false,
      showBtnFind: true,
      showBtnPrint: true,
      showBtnNew: true,
      showBtnClose: true,
      //
      settings: [],
      items: [],
      itemDefault: {
        "id": 0, "type": 1, "description": "", active: '1'
      },
    }),
    methods: {
      initialize: async function () {
        await this.reasonList();
      },
      btnPrint: async function () {
        //
      },
      btnClose: async function () {
        await this.$router.push({name: 'Logged', params: {}});
      },
      btnNew: async function () {
        this.$emit('setItemRow', this.itemDefault);
        this.$emit('tabChange', 2);
      },
      btnEdit: async function () {
        //
      },
      btnCancel: async function () {
        //
      },
      btnSave: async function () {
        //
      },
      btnFind: async function () {
        this.showFind = await !this.showFind;
        if(await this.showFind){
          await setTimeout(() => {
            this.$refs.text_find.focus();
          }, 500);
        } else {
          this.searchValue = await null;
        }
      },
      cancelFind: async function () {
        this.searchValue = await null;
        this.showFind = await false;
      },
      reasonList: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'reasonlistall';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // console.log(dataResponse);
          this.items = await dataResponse.data;
          // await alert(JSON.stringify(dataResponse));
        }
      },
      takeItem: async function (val) {
        let editedIndex = await this.items.indexOf(val);
        let a = await this.keyUniqueCodes;
        await a.splice(editedIndex, 1);
        await this.$store.dispatch('loadKeyUniqueCodes', a);
        await this.$emit('setItemRow', val);
        await this.$emit('tabChange', 2);
      },
      keymonitor: function (event) {
        if (event.key === 'Enter') {
          // *** 
        }
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      this.initialize();
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      filteredRecipes() {
        let tempRecipes = this.items;
        // Process search input
        if (this.searchValue !== '' && this.searchValue) {
            tempRecipes = tempRecipes.filter((item) => {
              if (item.description && item.id){
                return (
                  item.description
                  .toUpperCase()
                  .includes(this.searchValue.toUpperCase())
                )
              }
            })
        }
        return tempRecipes
      }
    }
  }
</script>
